import { CrudModel } from './_crud';

export class UserPublicModel extends CrudModel {
  constructor() {
    super('public/user');
  }

  async searchPsychologists(page: number, specialtyId?: number, psychologistName?: string, initialDate?: string, gender?: string, channel?: string, companyId?: number) {
    return this.post(null, 'search', { page, specialtyId, psychologistName, initialDate, gender, channel, companyId });
  }
}

export default new UserPublicModel();
