






































































































import { Vue, Component, Prop } from "vue-property-decorator";
import AusterInput from "../components/AusterInput.vue";
import AusterButton from "../components/AusterButton.vue";
import AusterSelect from "../components/AusterSelect.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { format } from "date-fns";

import specialtyModal from "@/models/specialty.model";
import userPublicModel from "@/models/user_public.model";
import userModel from "@/models/user.model";

@Component({
  components: {
    AusterInput,
    AusterButton,
    AusterSelect,
    ClipLoader,
  },
})
export default class SearchPsychologistComponent extends Vue {

  @Prop({ default: false })
  footer: boolean;

  loading = true;
  loadingMore = false;
  moreResultsAvailable: boolean = true;

  specialtiesOptions: any = [];
  psychologistResults: any = [];
  page: number = 1;

  searchName = "";
  searchSpecialty: number = 0;
  searchDate: string = format(new Date(), "yyyy-MM-dd");
  searchGender: string = "";
  searchChannel: string = "";

  genderOptions: any[] = [{ name: 'Todos', value: 'A' }, { name: 'Masculino', value: 'M' }, { name: 'Feminino', value: 'F' }, { name: 'Outro', value: 'O' }];
  genderTranslations = { A: "", M: "MALE", F: "FEMALE", O: "OTHER" }; // necessary to due vs-select bug

  channelOptions: any[] = [{ name: 'Todos', value: 'A' }, { name: 'FiqueOk Online', value: 'F' }, { name: 'Microsoft Teams', value: 'M' }, { name: 'Whatsapp', value: 'W' }];

  logado = true;
  companyId: number = 0;

  async mounted() {
    const user = JSON.parse(localStorage.getItem("activeUser"));
    const logado = user !== null && user.id > 0;
    if (logado) {
      const userInfo = await userModel.getProfile();
      console.log('user info', userInfo);
      if (userInfo && userInfo.company) {
        this.companyId = userInfo.company.id;
      }

      this.searchPsychologist();
      const specialties = await specialtyModal.search({});
      this.specialtiesOptions = specialties.map(specialty => ({ value: specialty.id, name: specialty.name }));
    }
  }

  gotoLogin() {

  }

  async searchPsychologist() {
    this.loading = true;

    // reset pagination and results before new search
    this.psychologistResults = [];
    this.moreResultsAvailable = true;
    this.page = 0;
    await this.loadMore();

    this.loading = false;
  }

  clearSearch() {
    this.searchName = "";
  }

  async loadMore() {
    this.page = this.page + 1;
    this.loadingMore = true;
    console.error('***** load more ****');
    try {
      const morePsychologistResults = await userPublicModel.searchPsychologists(this.page, this.searchSpecialty, this.searchName, this.searchDate, this.genderTranslations[this.searchGender], this.searchChannel, this.companyId);
      this.psychologistResults = this.psychologistResults.concat(morePsychologistResults);
      if (morePsychologistResults && (morePsychologistResults.length === 0 || morePsychologistResults.length < 20)) {
        this.moreResultsAvailable = false;
      }
    } catch (error) {
      console.error(error);
      this.$vs.notification({
        title: "Erro",
        text: error.message,
        color: "#FF6767",
      });
    }
    this.loadingMore = false;
  }
}
